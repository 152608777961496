import * as React from "react";
import { useState, useRef } from "react";
import Select from "../../../component/select";
import MenuItem from "@mui/material/MenuItem";
import moment from "moment";
import { useReactToPrint } from "react-to-print";
import Utils from "../../../utility";
import DeleteConfirmationPopup from "../../../component/modal/deleteModel";
import VisibilityIcon from "@mui/icons-material/Visibility";
import PrintGrn from "../../../component/printGrnPdf";
import BulkUploadGRN from "../storeStocks/bulkUploadGRN";
import AddUser from "../storeStocks/addstoreStocks";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import FilterListIcon from "@mui/icons-material/FilterList";
import { Button } from "@mui/material";
// import {
//   getBiller,
//   getOprationManagerList,
//   getStoreList,
// } from "../store/action";
import { getWarehouseList } from "../../warehouseRelated/warehouses/action";
import { getAllGRNList, getGRNById, deleteGRN } from "./action";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
// import DeleteIcon from '@mui/icons-material/Delete';
import { visuallyHidden } from "@mui/utils";
import { useDispatch, useSelector } from "react-redux";
import Stack from "@mui/material/Stack";
import Modal from "../../../component/modal";
import DetailGRN from "./detailGRN";
import { getUserTypeFromLs } from "../../../utility/commonFunction";
import { useNavigate } from "react-router-dom";
// import { permission } from "../../../utility/userPermissionData";
import AddButton from "../../../component/button/buttonWithIcon";
import Breadcrumbs from "../../../component/breadcrumb";
import SearchInput from "../../../component/searchInput";
import { getProductsForDropdown } from "../../productRelated/products/action";

const headCells = [
  {
    id: "id",
    numeric: true,
    disablePadding: true,
    label: "GRN ID",
    sorting: false,
  },
  {
    id: "storename",
    numeric: false,
    disablePadding: true,
    label: "Date",
    sorting: false,
  },
  {
    id: "address",
    numeric: false,
    disablePadding: true,
    label: "Time",
    sorting: false,
  },
  // {
  //   id: "Vendor ID",
  //   numeric: false,
  //   disablePadding: true,
  //   label: "Vendor ID",
  // },
  {
    id: "Vendor",
    numeric: false,
    disablePadding: true,
    label: "Vendor",
    sorting: false,
  },
  {
    id: "Done By",
    numeric: false,
    disablePadding: true,
    label: "Done By",
    sorting: false,
  },
  {
    id: "Excess/Short",
    numeric: false,
    disablePadding: true,
    label: "Excess/Short",
    sorting: false,
  },
  {
    id: "InvoiceNumber",
    numeric: false,
    disablePadding: true,
    label: "Invoice Number",
    sorting: false,
  },
  {
    id: "storename",
    numeric: true,
    disablePadding: false,
    label: "Store",
    sorting: true,
  },
  {
    id: "action",
    numeric: true,
    disablePadding: false,
    label: "Action",
    sorting: false,
  },
];

function EnhancedTableHead(props) {
  const {
    // onSelectAllClick,
    order,
    orderBy,
    // numSelected,
    // rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            // align={headCell.numeric ? "right" : "left"}
            // padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={
              orderBy === headCell?.id ? order.toLowerCase() : false
            }
          >
            {headCell?.sorting ? (
              <TableSortLabel
                active={orderBy === headCell?.id}
                direction={
                  orderBy === headCell?.id ? order.toLowerCase() : "asc"
                }
                onClick={createSortHandler(headCell?.id)}
              >
                {headCell.label}
                {orderBy === headCell?.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </Box>
                ) : null}
              </TableSortLabel>
            ) : (
              headCell.label
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  // numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  //onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["ASC", "DESC"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = (props) => {
  // const { numSelected } = props;

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        // ...(numSelected > 0 && {
        //   bgcolor: (theme) =>
        //     alpha(
        //       theme.palette.primary.main,
        //       theme.palette.action.activatedOpacity
        //     ),
        // }),
      }}
    >
      <Typography
        sx={{ flex: "1 1 100%" }}
        variant="h6"
        id="tableTitle"
        component="div"
      >
        GRN
      </Typography>
    </Toolbar>
  );
};

// EnhancedTableToolbar.propTypes = {
//   numSelected: PropTypes.number.isRequired,
// };

export default function EnhancedTable() {
  const dispatch = useDispatch();
  const [grandTotal, setGrandTotal] = useState(0);
  const [openAddGrn, setOpenAddGrn] = useState(false);
  const [openEditSS, setOpenEditSS] = useState(null);
  const [openDailyStock, setOpenDailyStock] = useState(false);
  const [openBulkUpload, setOpenBulkUpload] = useState(false);
  // const [dropdownStoreAndWarehouse, setdropdownStoreAndWarehouse] = useState(
  //   []
  // );
  const Navigate = useNavigate();
  const { permissions } = useSelector((state) => state.commonReducer);

  const { printGrnData, grandTotalAddedGRN } = useSelector(
    (state) => state.storeStockReducer
  );

  // const { grandTotalAddedGRN } = useSelector(
  //   (state) => state.storeStockReducer
  // );

  const [deleteConfirmation, setDeleteConfirmation] = useState(null);

  const [open, setOpen] = useState(false);

  const [isEdit, setIsEdit] = useState(null);
  const [storeId, setStoreId] = useState(null);
  React.useEffect(() => {
    // dispatch(getStoreList());
    // dispatch(getOprationManagerList());
    // dispatch(getBiller());
    // dispatch(getAllGRNList());
    // dispatch(getWarehouseList());
    // dispatch(getPropertyOwner());
  }, [dispatch]);

  const handleRequestSort = (event, property) => {
    const isAsc = sortField === property && order === "ASC";
    dispatch({
      type: Utils.ActionName.GRN,
      payload: { sortField: property, order: isAsc ? "DESC" : "ASC" },
    });
    dispatch(getAllGRNList());
  };

  const handleChangePage = (event, newPage) => {
    dispatch({
      type: Utils.ActionName.GRN,
      payload: { offset: newPage + 1 },
    });
    dispatch(getAllGRNList());
  };

  const handleChangeRowsPerPage = (event) => {
    dispatch({
      type: Utils.ActionName.GRN,
      payload: { take: event.target.value, offset: 1 },
    });
    dispatch(getAllGRNList());
  };

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  const { GRNList, order, type, sortField, offset, take, count, GRNDetail } =
    useSelector((state) => state.GRNReducer);
  // const { storeList } = useSelector((state) => state.storeReducer);
  // const { warehouseList } = useSelector((state) => state.warehouseReducer);
  const { storeListForDropdown } = useSelector((state) => state.commonReducer);

  // React.useEffect(() => {
  //   if (getUserTypeFromLs() === "B" || getUserTypeFromLs() === "PO")
  //     setdropdownStoreAndWarehouse(["store"]);
  //   if (getUserTypeFromLs() === "SA")
  //     setdropdownStoreAndWarehouse(["store", "warehouse"]);
  // }, []);
  return (
    <>
      <Breadcrumbs />
      <Box className="tableContainer">
        <div className="Dflex table-filter-head ">
          {/* <Stack direction="row" spacing={2} style={{ width: 270 }}>
              <Select
                handleChange={(e) => {
                  dispatch({
                    type: Utils.ActionName.GRN,
                    payload: { type: e.target.value },
                  });
                  setStoreId(null);
                }}
                value={type}
                label={
                  !["B", "PO"].includes(getUserTypeFromLs())
                    ? "Select Stores or warehouse"
                    : "Select Stores"
                }
                option={dropdownStoreAndWarehouse.map((item) => (
                  <MenuItem key={item} value={item}>
                    {item}
                  </MenuItem>
                ))}
                name="manager"
              />
            </Stack> */}
          <Stack direction="row" spacing={2} style={{ width: 200 }}>
            {/* {type === "warehouse" ? (
                <Select
                  handleChange={(e) => {
                    dispatch({
                      type: Utils.ActionName.GRN,
                      payload: {
                        id: e.target.value,
                        offset: 1,
                        take: 10,
                        count: 0,
                      },
                    });
                    setStoreId(e.target.value);
                    dispatch(getAllGRNList());
                  }}
                  value={storeId}
                  label={"Select Warehouse"}
                  option={warehouseListForDropdown.map((item) => (
                    <MenuItem key={item.id} value={item?.id}>
                      {item?.warehouseNickname}
                    </MenuItem>
                  ))}
                  name="manager"
                />
              ) : type === "store" ? ( */}
            <Select
              handleChange={(e) => {
                setStoreId(e.target.value);
                dispatch({
                  type: Utils.ActionName.GRN,
                  payload: {
                    id: e.target.value,
                    offset: 1,
                    take: 10,
                    count: 0,
                  },
                });
                dispatch(getAllGRNList());
              }}
              value={storeId || ""}
              label={"Select Stores"}
              option={
                storeListForDropdown?.length &&
                storeListForDropdown.map((item) => (
                  <MenuItem key={item?.id} value={item?.id}>
                    {item?.storename}
                  </MenuItem>
                ))
              }
              name="manager"
            />
            {/* ) : (
                <></>
              )} */}
          </Stack>
          <Stack direction="row" space={2}>
            {/* <Tooltip title="Please select store First"> */}
            {!permissions || permissions["storeStock"]?.add ? (
              <AddButton
                text="  Add GRN"
                className="btn-purple m-l-10"
                onPress={() => {
                  dispatch(getProductsForDropdown());
                  Navigate("/add-grn", { state: { storeId: storeId } });
                  setGrandTotal(0);
                }}
                // disabled={!storeId}
              />
            ) : (
              <></>
            )}
            {!permissions || permissions["storeStock"]?.bulkUpload ? (
              <Stack direction="row" spacing={2} sx={{ mx: 1 }}>
                <AddButton
                  text="GRN bulk import"
                  className="btn-purple m-l-10"
                  onPress={() => {
                    setOpenBulkUpload(true);
                  }}
                />
              </Stack>
            ) : (
              <React.Fragment></React.Fragment>
            )}
            {/* </Tooltip> */}
          </Stack>
        </div>
        <TableContainer>
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            // size={dense ? "small" : "medium"}
          >
            <EnhancedTableHead
              // numSelected={selected.length}
              order={order}
              orderBy={sortField}
              onRequestSort={handleRequestSort}
              // rowCount={storeList.length}
            />
            <TableBody>
              {GRNList[0]?.map((row, index) => {
                return (
                  <TableRow hover key={row?.id}>
                    <TableCell
                      component="th"
                      id={index}
                      scope="row"
                      onClick={() => {
                        dispatch(getGRNById(row?.id));
                        setOpen(true);
                      }}
                    >
                      {row?.id}
                    </TableCell>
                    <TableCell align="left">
                      {moment(row?.createdAt).format("DD-MM-YYYY")}
                    </TableCell>
                    <TableCell align="left">
                      {moment(row?.createdAt).format("LT")}
                    </TableCell>
                    {/* <TableCell align="left">
                      {row?.vendor?.id ? row?.vendor?.id : "-"}
                    </TableCell> */}
                    <TableCell align="left">{row?.vendor?.alias}</TableCell>
                    <TableCell align="left">{row?.createdBy?.name}</TableCell>
                    <TableCell align="left">{row?.fillRate}</TableCell>
                    <TableCell
                      align="left"
                      style={{
                        maxWidth: "50px",
                        textOverflow: "ellipsis" /* IE, Safari (WebKit) */,
                        overflow: "hidden" /* don't show excess chars */,
                        whiteSpace: "nowrap",
                      }}
                    >
                      {row?.invoiceNumber}
                      {/* <a
                          target="_blank"
                          href={}
                          style={{ borderBottom: "1px solid black" }}
                        >
                          <b> open Invoice</b>
                        </a> */}
                    </TableCell>
                    <TableCell align="left">{row?.store?.storename}</TableCell>

                    <TableCell align="left">
                      <>
                        <VisibilityIcon
                          onClick={() => {
                            dispatch(getGRNById(row?.id));
                            setOpen(true);
                          }}
                        />
                        {/* <DeleteIcon
                            onClick={() => setDeleteConfirmation(row?.id)}
                          /> */}
                      </>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25]}
          component="div"
          count={count}
          rowsPerPage={take}
          page={offset - 1}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Box>
      <Modal
        header={"GRN Detail"}
        children={
          <DetailGRN
            setOpen={setOpen}
            isEdit={isEdit}
            setIsEdit={setIsEdit}
            handlePrint={handlePrint}
          />
        }
        handleClose={() => {
          setOpen(false);
          setIsEdit(null);
        }}
        open={open}
        minWidth={1250}
      />
      <DeleteConfirmationPopup
        title="GRN"
        handleClose={() => setDeleteConfirmation(null)}
        okButtonClick={() => {
          dispatch(deleteGRN(deleteConfirmation));
          setDeleteConfirmation(null);
        }}
        open={deleteConfirmation}
        minWidth={500}
      />
      {open && (
        <PrintGrn
          printGrnData={GRNDetail}
          componentRef={componentRef}
          grandTotalAddedGRN={grandTotalAddedGRN}
        />
      )}
      <Modal
        header={`${isEdit ? "Edit" : "Add"} GRN`}
        children={
          <AddUser
            setOpen={setOpenAddGrn}
            isEdit={isEdit}
            setIsEdit={setIsEdit}
            storeId={storeId}
            handlePrint={handlePrint}
            grandTotal={grandTotal}
            setGrandTotal={setGrandTotal}
          />
        }
        handleClose={() => {
          setOpenAddGrn(false);
          setIsEdit(null);
        }}
        open={openAddGrn}
        minWidth={"95%"}
      />

      {(openAddGrn || openBulkUpload) && (
        <div
          ref={componentRef}
          style={{
            margin: "10px auto",
            width: "180.874016px",
            // border: "2px solid black",
          }}
        >
          <img
            src="https://thenewshop.in/header-logo.png"
            alt="The new shop"
            style={{ width: "170px" }}
          />
          <div style={{ margin: "0px 15px" }}>
            <div style={{ fontSize: 14, fontWeight: 500 }}>
              <b>GRN Id:</b> {printGrnData?.grnId || printGrnData?.id}
            </div>
            {grandTotalAddedGRN ? (
              <div style={{ fontSize: 14 }}>
                <b>Total Amount:</b>
                {grandTotalAddedGRN}
              </div>
            ) : (
              <React.Fragment></React.Fragment>
            )}

            <div style={{ fontSize: 14, fontWeight: 500 }}>
              <b>Store :</b>
              {(printGrnData?.grnProducts &&
                printGrnData?.grnProducts[0]?.grn?.store?.storename) ||
                printGrnData?.store?.storename}
            </div>
            <div style={{ fontSize: 14, fontWeight: 500 }}>
              <b> GRN By :</b>{" "}
              {(printGrnData?.grnProducts &&
                printGrnData?.grnProducts[0]?.grn?.createdBy?.name) ||
                printGrnData.createdBy?.name}
            </div>
            <div style={{ fontSize: 14, fontWeight: 500 }}>
              <b> Date :</b>
              {(printGrnData?.grnProducts &&
                moment(printGrnData?.grnProducts[0]?.grn?.createdAt).format(
                  "DD-MM-YYYY  HH:mm:ss"
                )) ||
                moment(printGrnData?.createdAt).format("DD-MM-YYYY  HH:mm:ss")}
            </div>
            <div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  fontSize: 14,
                  marginTop: 15,
                }}
              >
                <div>
                  <b>Product</b>
                </div>
                <div>
                  <b>Quantity</b>
                </div>
              </div>

              {printGrnData?.grnProducts?.length &&
                printGrnData?.grnProducts?.map((item, i) => (
                  <div
                    key={i}
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      fontSize: 12,
                      // textAlign:"center",
                    }}
                  >
                    <div style={{ maxWidth: "126px" }}>
                      {item?.product?.name}
                    </div>
                    <div>{item?.recievedQuantity}</div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      )}
      <Modal
        header={`Grn bulk upload`}
        children={
          <BulkUploadGRN
            setOpen={setOpenBulkUpload}
            storeId={storeId}
            openEditSS={openBulkUpload}
            handlePrint={handlePrint}
          />
        }
        handleClose={() => {
          setOpenBulkUpload(false);
          // setIsEdit(null);
        }}
        open={openBulkUpload}
        // minWidth={"85%"}
      />
    </>
  );
}
