import * as React from "react";
import { useState } from "react";
import Utils from "../../../utility";
// import DeleteConfirmationPopup from "../../../component/modal/deleteModel";
import Select from "../../../component/select";
import useDebounce from "../../../hooks/debounce";
import DatePicker from "../../../component/date";
import MenuItem from "@mui/material/MenuItem";
import VisibilityIcon from "@mui/icons-material/Visibility";
import {
  // deleteProduct,
  getOrderList,
  // getOrderExportList,
  orderInvoice,
  // getOrderListInInterval,
} from "./action";
import { getStoreList } from "../../storeRelated/store/action";
import PropTypes from "prop-types";
import { alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
// import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
// import Tooltip from "@mui/material/Tooltip";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
// import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
// import DeleteIcon from '@mui/icons-material/Delete';
// import FilterListIcon from "@mui/icons-material/FilterList";
import { visuallyHidden } from "@mui/utils";
import { useDispatch, useSelector } from "react-redux";
import Stack from "@mui/material/Stack";
import Modal from "../../../component/modal";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import AddUser from "./generateReport";
import { CSVLink } from "react-csv";
import moment from "moment";
import AddButton from "../../../component/button/buttonWithIcon";
import Breadcrumbs from "../../../component/breadcrumb";
import SearchInput from "../../../component/searchInput";
import { clearInvoiceDetails } from "./reducer";
import ReactToPrint from "react-to-print";
import AutoComplete from "../../../component/input/autocomplete";

const headers = [
  { label: "Invoice", key: "Invoice" },
  { label: "orderDate", key: "orderDate" },
  { label: "Time", key: "time" },
  { label: "Product Id", key: "productId" },
  { label: "Product Name", key: "productName" },
  { label: "barcode", key: "barcode" },
  { label: "total Product Price", key: "totalProductPrice" },
  { label: "sellingPrice", key: "sellingPrice" },
  { label: "discountAmount", key: "discountAmount" },
  { label: "orderAmountAfterDiscount", key: "orderAmountAfterDiscount" },
  { label: "quantity", key: "quantity" },
  { label: "HSNCode", key: "HSNCode" },
  { label: "GST", key: "gst" },
  { label: "AcessAmount", key: "AcessAmount" },
  { label: "CessAmount", key: "CessAmount" },
  { label: "orderAmountTotal", key: "orderAmountTotal" },
  { label: "orderAmountTax", key: "orderAmountTax" },
  { label: "paymentMethod", key: "paymentMethod" },
  { label: "description", key: "description" },
  { label: "Brand Name", key: "brandName" },
  { label: "Category Name", key: "categoryName" },
  { label: "Storename", key: "storename" },
  { label: "StoreAddress", key: "storeAddress" },
  { label: "storeCity", key: "storeCity" },
  { label: "storeState", key: "storeState" },
  { label: "storePinCode", key: "storePinCode" },
  { label: "storeGSTIN", key: "storeGSTIN" },
  { label: "orderType", key: "orderType" },
  { label: "Commition", key: "Commition" },
  { label: "Customer name", key: "CustomerName" },
  // {
  //   label: "Customer GSTIN",
  //   key: "CustomerGSTIN",
  // },
  // {
  //   label: "Customer Address",
  //   key: "CustomerAddress",
  // },
  // { label: "Customer State", key: "CustomerState" },
  // { label: "Customer City", key: "CustomerCity" },
  // { label: "Customer Pincode", key: "CustomerPincode" },
  { label: "orderFrom", key: "orderFrom" },

  { label: "storageType", key: "storageType" },
  { label: "weight", key: "weight" },
  { label: "Date", key: "Date" },
  // { label: "Phone ", key: "Phone" },
  { label: "Payment type", key: "PaymentType" },
  // { label: "Short Quantity", key: "ShortQuantity" },
  { label: "Runner", key: "runner" },
  { label: "orderTime", key: "orderTime" },
  { label: "orderStatus", key: "orderStatus" },

  // { label: "orderAmountNet", key: "orderAmountNet" },
  { label: "notes", key: "notes" },
];
const headCells = [
  {
    id: "id",
    numeric: true,
    disablePadding: true,
    label: "Invoice",
    sorting: false,
  },
  {
    id: "orderDate",
    numeric: false,
    disablePadding: true,
    label: "Date",
    sorting: true,
  },
  {
    id: "name",
    numeric: false,
    disablePadding: false,
    label: "Customer name",
    // sorting: true,
  },
  {
    id: "barCode",
    numeric: true,
    disablePadding: false,
    label: "Phone",
    sorting: false,
  },
  {
    id: "totalBillAmount",
    numeric: true,
    disablePadding: false,
    label: "Amount",
    sorting: false,
  },
  // {
  //   id: "description",
  //   numeric: true,
  //   disablePadding: false,
  //   label: "Payment type",
  //   sorting: false,
  // },
  // {
  //   id: "categoryName",
  //   numeric: true,
  //   disablePadding: false,
  //   label: "Commition",
  //   sorting: false,
  // },
  {
    id: "action",
    numeric: true,
    disablePadding: false,
    label: "Action",
    sorting: false,
  },
];

function EnhancedTableHead(props) {
  const { onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  const { order, sortField } = useSelector((state) => state.orderReducer);
  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            sortDirection={
              sortField === headCell.id ? order.toLowerCase() : false
            }
          >
            {headCell?.sorting ? (
              <TableSortLabel
                active={sortField === headCell.id}
                direction={
                  sortField === headCell.id ? order.toLowerCase() : "asc"
                }
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {sortField === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === "DESC"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </Box>
                ) : null}
              </TableSortLabel>
            ) : (
              headCell.label
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["ASC", "DESC"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = (props) => {
  const dispatch = useDispatch();
  const { numSelected, handleStoreSelect, storeId } = props;
  const { storeListForDropdown } = useSelector((state) => state.commonReducer);
  const { fromDate, toDate, take } = useSelector((state) => state.orderReducer);
  return (
    <Toolbar
      sx={{
        paddingTop: 2,
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity
            ),
        }),
      }}
    >
      <Typography
        sx={{ flex: "1 1 100%" }}
        variant="h6"
        id="tableTitle"
        component="div"
      >
        Order
      </Typography>
      <Stack
        sx={{
          marginLeft: 1,
          width: "100%",
          display: "flex",
          flexDirection: "row",
        }}
      >
        <Stack spacing={1} style={{ width: "100%" }}>
          <DatePicker
            value={fromDate}
            name="fromDate"
            handleChange={(newVal) => {
              dispatch({
                type: Utils.ActionName.ORDER,
                payload: { fromDate: newVal, take: take, offset: 1 },
              });
              if (storeId) dispatch(getOrderList());
            }}
            label="From Date"
            maxDate={new Date(moment(new Date()).subtract(1, "days"))}
            minDate={new Date(moment(new Date()).subtract(30, "days"))}

            // minDate = {}
            // maxDate ={new Date()}
          />
        </Stack>
        <Stack direction="row" spacing={3} style={{ width: "100%" }}>
          <DatePicker
            value={toDate}
            name="toDate"
            handleChange={(newVal) => {
              dispatch({
                type: Utils.ActionName.ORDER,
                payload: { toDate: newVal, take: take, offset: 1 },
              });
              if (storeId) {
                dispatch(getOrderList());
              }
            }}
            label="To Date"
            maxDate={new Date(moment(new Date()))}
            minDate={new Date(moment(new Date(fromDate)))}
            // error={errors.toDate}
          />
        </Stack>
        <AutoComplete
          freeSolo={false}
          // onBlur={handleBlur}
          values={storeId}
          onChange={(e,newVal) => {
            debugger
            dispatch({
              type: Utils.ActionName.ORDER,
              payload: { storeId: newVal?.id},
            });
            dispatch(getOrderList());
            // dispatch(getOrderExportList());
          }}
          // handleStoreSelect}
          // (event, value) => {
          // if (value !== null) {
          //   setStoreId(event.target.value);
          //   dispatch({
          //     type: Utils.ActionName.STORE_STOCK,
          //     payload: { take: take, offset: 1 },
          //   });
          //   dispatch(getStoreStockList(event.target.value));
          // }
          //
          //   setStoreId(value?.id);
          //   dispatch({
          //     type: Utils.ActionName.DASHBOARD,
          //     payload: {
          //       storeId: value?.id,
          //     },
          //   });
          // dispatch(getDashboardTrend(value?.id));
          // setFieldValue("storeName", value?.storename);
          // }
          // }}
          name={"storeId"}
          label={"Store"}
          options={storeListForDropdown}
          getOptionLabel={(option) => option?.storename}
          // isRequired={true}
          getOptionSelected={(option, value) => {
            return `${option?.id}` === `${value?.id}`;
          }}
        />
        {/* <Select
          handleChange={handleStoreSelect}
          value={storeId}
          label={"Select Stores"}
          option={storeListForDropdown?.map((item) => (
            <MenuItem value={item?.id} key={item.id}>
              {item?.storename}
            </MenuItem>
          ))}
          name="Stores"
        /> */}
      </Stack>
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

export default function EnhancedTable() {
  const dispatch = useDispatch();
  // const [song, setSong] = useState(
  //   new Audio(
  //     "https://2u039f-a.akamaihd.net/downloads/ringtones/files/mp3/i-love-you-mummy-43690.mp3"
  //   )
  // );
  // let song = React.useMemo();

  const [open, setOpen] = useState(false);
  // const [deleteConfirmation, setDeleteConfirmation] = useState(null);
  const [isEdit, setIsEdit] = useState(null);
  const [cvcData, gets2wList] = useState([]);
  // const ref = React.useRef(null);

  const {
    orderList,
    order,
    sortField,
    offset,
    take,
    search,
    count,
    storeId,
    exportData,
    invoiceHtml,
  } = useSelector((state) => state.orderReducer);
  const { permissions } = useSelector((state) => state.commonReducer);

  const debouncedSearch = useDebounce(search, 500);
  const csvDownload = React.useRef(null);

  React.useEffect(() => {
    if (storeId || !permissions) dispatch(getOrderList());
  }, [debouncedSearch]);

  // React.useEffect(() => {
  //   dispatch(getStoreList());
  // }, [dispatch]);

  const handleRequestSort = (event, property) => {
    const isAsc = sortField === property && order === "ASC";
    dispatch({
      type: Utils.ActionName.ORDER,
      payload: { sortField: property, order: isAsc ? "DESC" : "ASC" },
    });
    dispatch(getOrderList());
  };

  const handleChangePage = (event, newPage) => {
    dispatch({
      type: Utils.ActionName.ORDER,
      payload: { offset: newPage + 1 },
    });
    dispatch(getOrderList());
  };

  const handleChangeRowsPerPage = (event) => {
    dispatch({
      type: Utils.ActionName.ORDER,
      payload: { take: event.target.value, offset: 1 },
    });
    dispatch(getOrderList());
  };

  // Avoid a layout jump when reaching the last page with empty rows.

  const handleSearch = (e) => {
    dispatch({
      type: Utils.ActionName.ORDER,
      payload: { search: e.target.value, take: take, offset: 1 },
    });
  };
  const handleSearchClear = (e) => {
    dispatch({
      type: Utils.ActionName.ORDER,
      payload: { search: "", take: take, offset: 1 },
    });
  };
  React.useEffect(() => {
    if (exportData?.length) {
      var newExportData = [];
      const GRNCVC = exportData.forEach((item) => {
        // var newExportData = [];
        item?.actualBillingSent?.length &&
          item?.actualBillingSent.forEach((exp, j) => {
            newExportData = [
              ...newExportData,
              {
                Invoice: item?.stateInvoiceNo,
                AcessAmount: exp?.AcessAmount,
                CessAmount: exp?.CessAmount,
                createdAt: moment(exp?.createdAt).format("DD-MM-YYYY"),
                quantity: exp?.quantity,
                sellingPrice: exp?.sellingPrice,
                discountAmount: j === 0 ? item?.discountAmount : 0,

                productName: exp?.product?.name,
                description: exp?.product?.description,
                brandName: exp?.product?.brands?.brandName,
                categoryName: exp?.product?.category?.categoryName,
                barcode: `${exp?.product?.barcode}`,
                productId: exp?.product?.id,
                HSNCode: exp?.product?.HSNCode,
                storageType: exp?.product?.storageType,
                weight: exp?.product?.weight,

                Date: item?.orderDate,
                CustomerName: item?.customer?.name,

                // Phone: item?.customer?.phoneNumber,
                PaymentType: item?.paymentMethod,
                Commition: item?.commissionDue,
                runner: item?.runner?.name,
                storename: item?.store?.storename,
                paymentMethod: item?.paymentMethod,
                orderType: item?.orderType,
                orderTime: item?.orderTime,
                orderStatus: item?.orderStatus,
                storeAddress: item?.store?.address,
                storeCity: item?.store?.city,
                storeState: item?.store?.stateCode,
                storePinCode: item?.store?.pincode,
                storeGSTIN: item?.store?.GSTIN,
                GST: exp?.product?.GST,
                orderFrom: item?.orderFrom,
                orderDate: item?.orderDate,
                orderAmountTotal: item?.orderAmountTotal,
                orderAmountTax: item?.orderAmountTax,
                // orderAmountNet: item?.orderAmountNet,

                orderAmountAfterDiscount:
                  j === 0 ? item?.orderAmountAfterDiscount : "",
                totalProductPrice: exp?.sellingPrice * exp?.quantity,
                notes: item?.notes,
              },
            ];
            return;
          });
        return newExportData;
      });
      //
      gets2wList(newExportData);
    }
  }, [exportData]);

  // console.log(ref?.current, "ref");
  // React.useEffect(() => {
  //   // song.play();
  //   const interval = setInterval(
  //     () =>
  //       dispatch(
  //         getOrderListInInterval((res,preCount) => {
  //           if (res > count) {
  //
  //             song.play();
  //             setTimeout(() => {
  //               song.pause();
  //             }, 5000);
  //             dispatch({
  //               type: Utils.ActionName.ORDER,
  //               payload: {
  //                 // orderList: respData.data?.order,
  //                 count: res,
  //               },
  //             });
  //             console.log(count, "interval");
  //           }
  //         })
  //       ),
  //     30000
  //   );

  //   return () => {
  //     if (interval) {
  //       clearInterval(interval);
  //     }
  //   };
  // }, []);
  // console.log(count, "interval1");

  const componentRef = React.useRef(null);
  const reactToPrintTrigger = React.useRef();
  const handleClearInvoiceHtml = () => {
    dispatch(clearInvoiceDetails());
  };
  React.useEffect(() => {
    if (invoiceHtml && reactToPrintTrigger.current) {
      reactToPrintTrigger.current.handlePrint();
    }
  }, [invoiceHtml]);

  return (
    <>
      <Breadcrumbs />
      <Box className="tableContainer">
        <div className="Dflex table-filter-head ">
          {/* <Button
              variant="outlined"
              startIcon={<AddCircleOutlineOutlinedIcon />}
              onClick={() => setOpen(true)}
            >
             
            </Button> */}

          <AddButton
            text="Generate a report"
            className="btn-purple m-l-10"
            onPress={() => setOpen(true)}
          />
          <Box className="search_bar ">
            <SearchInput
              value={search}
              placeholder="Search here..."
              handleSearch={handleSearch}
              clearSearch={handleSearchClear}
            />
          </Box>
        </div>
        {/* <h1 onClick={() => song.play()}>hi</h1>
          <h1 onClick={() => song.pause()}>Pouse</h1> */}

        <TableContainer>
          <EnhancedTableToolbar
            storeId={storeId}
            handleStoreSelect={(e) => {
              dispatch({
                type: Utils.ActionName.ORDER,
                payload: { storeId: e.target.value },
              });
              dispatch(getOrderList());
              // dispatch(getOrderExportList());
            }}
          />
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            // size={dense ? "small" : "medium"}
          >
            <EnhancedTableHead
              order={order}
              orderBy={sortField}
              onRequestSort={handleRequestSort}
            />
            <TableBody>
              {orderList?.length &&
                orderList.map((row, index) => {
                  return (
                    <TableRow hover tabIndex={-1} key={row.id}>
                      <TableCell component="th" id={index} scope="row">
                        {row?.stateInvoiceNo}
                      </TableCell>
                      <TableCell align="left">
                        {/* {row?.orderDate ? row?.orderDate : "-"}<br/> */}
                        {row?.orderDate
                          ? moment
                              .utc(row?.orderDate)
                              .format("YYYY-MM-DD || hh:mm:ss A")
                          : "-"}
                      </TableCell>
                      <TableCell align="left">
                        {row?.customer?.name ? row?.customer?.name : "N/A"}
                      </TableCell>
                      <TableCell align="left">
                        {row?.customer?.phoneNumber
                          ? row?.customer?.phoneNumber
                          : "N/A"}
                      </TableCell>
                      <TableCell align="left">
                        {row?.orderAmountTotal ? row?.orderAmountTotal : "N/A"}
                      </TableCell>
                      {/* <TableCell align="left">
                          {row?.paymentMethod ? row?.paymentMethod : "-"}
                        </TableCell>
                        <TableCell align="left">{row?.commissionDue}</TableCell> */}
                      <TableCell align="left">
                        {storeId ? (
                          <>
                            <VisibilityIcon
                              onClick={() => {
                                dispatch(orderInvoice(row?.id));
                              }}
                            />
                          </>
                        ) : (
                          <></>
                        )}
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 50]}
          component="div"
          count={
            take === orderList?.length
              ? (offset + 1) * take
              : (offset - 1) * take + orderList?.length
          }
          rowsPerPage={take}
          page={offset - 1}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Box>
      <Modal
        header={`Generate Billing Report`}
        children={
          <AddUser
            csvDownload={csvDownload}
            setOpen={setOpen}
            isEdit={isEdit}
            setIsEdit={setIsEdit}
          />
        }
        handleClose={() => {
          setOpen(false);
          setIsEdit(null);
        }}
        open={open}
      />
      <CSVLink
        data={cvcData}
        headers={headers}
        filename={"order"}
        ref={csvDownload}
        target="_blank"
      />
      {invoiceHtml && (
        <ReactToPrint
          content={() => componentRef.current}
          ref={reactToPrintTrigger}
          onAfterPrint={handleClearInvoiceHtml}
        />
      )}

      {invoiceHtml && (
        <div
          id="invoice-printable"
          ref={componentRef}
          dangerouslySetInnerHTML={{ __html: invoiceHtml }}
        />
      )}

      {/* {console.log(csvDownload, "csvDownload")} */}
      {/* Generate a report
      </CSVLink> */}
      {/* <DeleteConfirmationPopup
        title="Product"
        handleClose={() => setDeleteConfirmation(null)}
        okButtonClick={() => {
          dispatch(deleteProduct(deleteConfirmation));
          setDeleteConfirmation(null);
        }}
        open={deleteConfirmation}
        minWidth={500}
      /> */}
    </>
  );
}
