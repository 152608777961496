import * as Yup from "yup";
//@ts-ignore
// import mapValues from "lodash/mapValues";
// import { max } from "moment";
/**
 * @description
 * @param t language
 */
const numReg = /^[0-9]*$/;
/**
 * @description
 * @param t language
 */

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
const IFSCReg = /^[A-Za-z]{4}[a-zA-Z0-9]{7}$/;
const GSTINReg = /\d{2}[A-Z]{5}\d{4}[A-Z]{1}[A-Z\d]{1}[Z]{1}[A-Z\d]{1}/;
const pancardReg = /[A-Z]{5}[0-9]{4}[A-Z]{1}/;

const addUserSchema = () =>
  Yup.object().shape({
    userType: Yup.string().required("Required"),
    fullName: Yup.string()
      .required("Required")
      .min(4, "Min name is 4 char."),
      // .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field "),
    username: Yup.string()
      .required("Required")
      .min(4, "Min username is 4 char."),
    // password: Yup.string().required("Required").nullable(),
    phone: Yup.string()
      .required("Required")
      .matches(phoneRegExp, "Please enter valid phone number")
      .max(10, "Please enter valid phone number"),
    address: Yup.string().required("Required").max(50, "Max 50").nullable(),
    state: Yup.string().required("Required").nullable(),
    city: Yup.string().required("Required").nullable(),
    pincode: Yup.string()
      .required("Required")
      .max(6, "Pin number is not greater than 6")
      .nullable(),
    PAN: Yup.string()
      .required("Required")
      .max(10, "Adhar card number is not greater than 10")
      .matches(pancardReg, "Please enter valid pan card number")
      .nullable(),
    aadhar: Yup.string()
      .required("Required")
      .max(12, "Adhar card number is not greater than 12")
      .nullable(),
    officeLocation: Yup.string()
      .required("Required")
      .max(50, "Max 50")
      .nullable(),
    post: Yup.string().required("Required").max(50, "Max 50").nullable(),
    leaveDate: Yup.string().required("Required").nullable(),
    email: Yup.string()
      .required("Email is required")
      .email("Please enter valid email id")
      .nullable(),
  });

const storeSchema = () =>
  Yup.object().shape({
    // manager: Yup.array().required("Required"),
    storeName: Yup.string().required("Required"),
    // operator: Yup.string().required("Required"),
    // runner: Yup.array().required("Required"),
    // propertyOwner: Yup.string().required("Required"),
    GSTIN: Yup.string()
      .required("Required")
      .matches(GSTINReg, "Please enter valid GSTIN"),
    pincode: Yup.string()
      .required("Required")
      .max(6, "Pin number is not greater than 6")
      .nullable(),
    phoneNumber: Yup.string().required("Required"),
    FSSAI: Yup.string().required("Required"),
    state: Yup.string().required("Required"),
    city: Yup.string().required("Required"),
    address: Yup.string().required("Required"),
    type: Yup.string().required("Required"),
    locationType: Yup.string().required("Required"),
    lat: Yup.string().required("Required"),
    long: Yup.string().required("Required"),
    place: Yup.string().required("Required"),
    gstName: Yup.string().required("Required"),
    ownerNumber: Yup.string().required("Required"),
    ownerEmail: Yup.string()
      .required("Required")
      .email("Please enter valid email id"),
  });

const warehouseSchema = () =>
  Yup.object().shape({
    manager: Yup.array().required("Required"),
    // assistantManager: Yup.string().required("Required"),
    operator: Yup.array().required("Required"),
    warehouseAddress: Yup.string().required("Required"),
    warehouseState: Yup.string().required("Required"),
    warehousePincode: Yup.string()
      .required("Required")
      .max(6, "Pin number is not greater than 6")
      .nullable(),
    warehouseGSTIN: Yup.string()
      .required("Required")
      .matches(GSTINReg, "Please enter valid GSTIN"),
    warehouseNickname: Yup.string().required("Required"),
  });

const propertyOwnerSchema = () =>
  Yup.object().shape({
    store: Yup.array().min(1).required("Store is Required"),
    propertyOwner: Yup.string().required("PropertyOwner is Required"),
    bankAccountNumber: Yup.string()
      .required("Bank Account number number is required")
      // .min(10, "Bank Account number having minimum 10 nuber")
      .max(18, "Bank Account number having maximum 18 nuber"),
    bankIFSC: Yup.string()
      .required("bank IFSC is Required")
      .matches(IFSCReg, "Please enter valid IFSC code"),
    bankBranch: Yup.string().required("BankBranch is Required"),
    bankName: Yup.string()
      .required("Bank Name is Required")
      .max(50, "Maximum length is 50"),
    CIN: Yup.string().required("CIN is Required"),
    GST: Yup.string()
      .required("GST is Required")
      .matches(GSTINReg, "Please enter valid GSTIN"),
  });

const AddProductSchema = () =>
  Yup.object().shape({
    name: Yup.string().trim().required("Required"),
    barcode: Yup.string()
      .trim()
      .required("Required")
      .max(19, "Maximum length is 19")
      // .matches("/^d+$/", "Only number allowed")
      .test(
        "maxLenght",
        "Only Number is allowed and should not started with 0",
        (value) => {
          // debugger
          if (value?.charAt(0) == 0) {
            return false;
          }
          if (numReg.test(+value)) {
            //
            return true;
          } else return false;
        }
      ),
    productMrp: Yup.string().required("Required"),
    category: Yup.string().required("Required"),
    unit: Yup.string().required("Required").nullable(),
    quantity: Yup.string().required("Required").nullable(),
    GST: Yup.string().required("Required").nullable(),
    // .matches(GSTINReg, "Please enter valid GSTIN"),
    brands: Yup.string().required("Required"),
    // quantity: Yup.string().when("isIngredient", {
    //   is: true,
    //   then: Yup.string().required("Field is required").nullable(),
    //   otherwise: Yup.string(),
    // }),
    // unit: Yup.string().when("isIngredient", {
    //   is: true,
    //   then: Yup.string().required("Field is required").nullable(),
    //   otherwise: Yup.string(),
    // }),
    HSNCode: Yup.string()
      .required("Required")
      .max(8, "Maximum length is 8")
      .min(4, "Minimum length is 4")
      .test("maxLenght", "Only Number is allowed", (value) => {
        // debugger
        // if (value?.charAt(0) == 0) {
        //   return false;
        // }
        if (numReg.test(+value)) {
          //
          return true;
        } else return false;
      }),
    // commissionPercentage: Yup.number().required("Required"),
    // productACess: Yup.number().required("Required"),
    // productCess: Yup.number().required("Required"),
    // storageType: Yup.string().required("Required"),
    // deliveryType: Yup.string().required("Required"),
  });
const brandSchema = () =>
  Yup.object().shape({
    brandName: Yup.string().required("Required"),
  });

const productCategorySchema = () =>
  Yup.object().shape({
    categoryName: Yup.string().required("Required"),
  });

const warehouseStockSchema = () =>
  Yup.object({
    product: Yup.array().of(
      Yup.object().shape({
        product: Yup.string().required("required").nullable(),
        batch: Yup.string().required("required").nullable(),
        invoiceQuantity: Yup.string().required("required").nullable(),
        recievedQuantity: Yup.string().required("required").nullable(),
        // shortQuantity: Yup.string().required("required").nullable(),

        MRP: Yup.string().required("required").nullable(),

        totalAmount: Yup.string().required("required").nullable(),
      })
    ),
    // quantity: Yup.string().required("Required"),
    invoice: Yup.string().required("Required"),
    vendor: Yup.string().required("Required"),
    PONumber: Yup.string().required("Required").nullable(),
    vehicle: Yup.string().required("Required").nullable(),

    // quantity: Yup.string().required("Required"),
  });

const storeStockSchema = () =>
  Yup.object({
    product: Yup.array().of(
      Yup.object().shape({
        product: Yup.string().required("required").nullable(),
        // invoiceQuantity: Yup.string().required("required").nullable(),
        recievedQuantity: Yup.string()
          .required("required")
          .nullable()
          .test(
            "maxval",
            "Quantity should not greater than 10000.00",
            (value) => {
              if (+value > 10000) {
                return false;
              } else return true;
            }
          ),
        costPrice: Yup.string()
          .required("required")
          .nullable()
          .test(
            "maxval",
            "Cost Price should not greater than 100000.oo",
            (value) => {
              if (+value > 100000) {
                return false;
              } else return true;
            }
          ),
        sellingPrice: Yup.string()
          .required("required")
          .nullable()
          .test(
            "maxval",
            "Selling price should not greater than 100000.00",
            (value) => {
              if (+value > 100000) {
                return false;
              } else return true;
            }
          ),
        // mrp: Yup.string().required("required").nullable(),
        total: Yup.string().required("required").nullable(),
      })
    ),
    // invoice: Yup.string().required("Required"),
    vendor: Yup.string().required("Required"),
    // PONumber: Yup.string().required("Required").nullable(),
    invoiceNumber: Yup.string().required("Required").nullable(),

    // quantity: Yup.string().required("Required"),
  });
const RecipeSchema = () =>
  Yup.object({
    product: Yup.array().of(
      Yup.object().shape({
        product: Yup.string().required("required").nullable(),
        quantity: Yup.string().required("required").nullable(),
        unit: Yup.string().required("required").nullable(),
      })
    ),
    // recipe: Yup.string().required("Required").nullable(),

    // quantity: Yup.string().required("Required"),
  });
const RecipeNameSchema = () =>
  Yup.object({
    recipeName: Yup.string().required("required").nullable(),
    product: Yup.string().required("required").nullable(),

    // quantity: Yup.string().required("Required"),
  });

const brandOwnerSchema = () =>
  Yup.object().shape({
    // brandName: Yup.string().required("Required"),
    // userId: Yup.string().required("Required").nullable(),
    // allowedBrands: Yup.string().required("Required").nullable(),
    // bannedBrands: Yup.string().required("Required").nullable(),
    // bankName: Yup.string().required("Required").nullable(),
    // bankBranch: Yup.string().required("Required").nullable(),
    // bankIFSC: Yup.string().required("Required").nullable(),
    // bankAccountNumber: Yup.string().required("Required").nullable(),
    // licenseType: Yup.string().required("Required").nullable(),
    // licenseNumber: Yup.string().required("Required").nullable(),
    // GST: Yup.string().required("Required").nullable(),
    alias: Yup.string().required("Required"),
    GST: Yup.string()
      .required("Required")
      .matches(GSTINReg, "Please enter valid GSTIN"),
    address: Yup.string().required("Required"),
    // mobileNumber: Yup.string().required("Required"),
    // contactPersion: Yup.string().required("Required"),
  });

/****Schema for warehouse to store */
const makeStockTransferW2S = () =>
  Yup.object({
    stocks: Yup.array().of(
      Yup.object().shape({
        barcode: Yup.string().required("required").nullable(),
        quantity: Yup.string().required("required").nullable(),
      })
    ),
    warehouseTo: Yup.string().required("Required").nullable(),
    store: Yup.string().required("Required").nullable(),
    // quantity: Yup.string().required("Required"),
  });
/****Schema for warehouse to store */
const makeStockTransferW2W = () =>
  Yup.object({
    stocks: Yup.array().of(
      Yup.object().shape({
        product: Yup.string().required("required").nullable(),
        quantity: Yup.string().required("required").nullable(),
      })
    ),
    warehouseTo: Yup.string().required("Required").nullable(),
    warehouse: Yup.string().required("Required").nullable(),
    // quantity: Yup.string().required("Required"),
  });

/****Schema for warehouse to store */
const makeStockTransferS2W = () =>
  Yup.object({
    product: Yup.array().of(
      Yup.object().shape({
        product: Yup.string().required("required").nullable(),
        quantity: Yup.string().required("required").nullable(),
      })
    ),
    store: Yup.string().required("Required").nullable(),
    warehouse: Yup.string().required("Required").nullable(),
    // quantity: Yup.string().required("Required"),
  });
/****Schema for warehouse to store */
const makeStockTransferS2S = () =>
  Yup.object({
    stocks: Yup.array().of(
      Yup.object().shape({
        product: Yup.string().required("required").nullable(),
        quantity: Yup.string().required("required").nullable(),
      })
    ),
    storeTo: Yup.string().required("Required").nullable(),
    store: Yup.string().required("Required").nullable(),
    // quantity: Yup.string().required("Required"),
  });

/****Schema for SKU time line filter */
const SKUTimelineSchema = () =>
  Yup.object({
    storeId: Yup.string().required("Required").nullable(),
    productId: Yup.string().required("Required").nullable(),
    // fromDate: Yup.string().required("Required").nullable(),
    // toDate: Yup.string().required("Required").nullable(),

    // quantity: Yup.string().required("Required"),
  });
// newPassword;
// confirmPassword;
const ChangePassword = () =>
  Yup.object({
    newPassword: Yup.string().required("New Password is required"),
    confirmPassword: Yup.string()
      .required("Confirm Password is required")
      .oneOf([Yup.ref("newPassword"), null], "Passwords must match"),
  });

const LedgerSchema = () =>
  Yup.object({
    storeId: Yup.string().required("Please select Store").nullable(),
    email: Yup.string()
      .required("Email is required")
      .email("Please enter valid email id")
      .nullable(),
    fromDate: Yup.string().required("Required").nullable(),
    toDate: Yup.string().required("Required").nullable(),
    // quantity: Yup.string().required("Required"),
  });

const grnBulkUploadSchema = () =>
  Yup.object({
    storeId: Yup.string().required("Please select Store").nullable(),

    vendorId: Yup.string().required("Required").nullable(),
    invoice: Yup.string().required("Required").nullable(),
  });
const Schema = {
  addUserSchema,
  storeSchema,
  warehouseSchema,
  propertyOwnerSchema,
  AddProductSchema,
  brandSchema,
  productCategorySchema,
  brandOwnerSchema,
  warehouseStockSchema,
  storeStockSchema,
  makeStockTransferW2S,
  makeStockTransferW2W,
  makeStockTransferS2S,
  makeStockTransferS2W,
  SKUTimelineSchema,
  ChangePassword,
  LedgerSchema,
  RecipeSchema,
  RecipeNameSchema,
  grnBulkUploadSchema,
};

export default Schema;
