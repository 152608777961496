import * as React from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import "./input.scss";

export default function Index({
  type,
  name,
  error,
  onChange,
  label,
  handleBlur,
  value,
  disabled,
  inputProps,
  minWidth,
  maxLength,
  multiline,
  maxRows,
  startAdornment,
  className
}) {
  const [showPassword, setShowPassword] = React.useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);

  return (
    <Box>
      <TextField
        type={showPassword ? "text" : type}
        value={value}
        className={className}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            e.preventDefault();
          }
          if (type === "number") {
            // if (+e.target.value < 0) {
            //
            //   e.preventDefault();
            // }
            ["e", "E", "+", "-", "ArrowDown", "ArrowUp"].includes(e.key) &&
              e.preventDefault();
          }
        }}
        name={name}
        inputProps={inputProps}
        error={error}
        helperText={error}
        fullWidth
        disabled={disabled}
        label={label}
        id={name}
        multiline={multiline}
        maxRows={maxRows}
        size="small"
        onFocus={(e) => {
          // if (+e.target.value < -1) e.preventDefault();
          if (type == "number")
            e.target.addEventListener(
              "wheel",
              function (e) {
                e.preventDefault();
              },
              { passive: false }
            );
        }}
        // inputProps={{
        //   maxLength: 11,
        // }}
        onChange={onChange}
        // startAdornment={<InputAdornment position="start">$</InputAdornment>}
        onBlur={handleBlur}
        InputProps={
          type == "password"
            ? {
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      // onMouseDown={handleMouseDownPassword}
                      // edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }
            : startAdornment && {
                startAdornment: (
                  <InputAdornment position="start" sx={{ ml: 0.5 }}>
                    +91
                  </InputAdornment>
                ),
              }
        }
      />
    </Box>
  );
}
