const actionName = {
  USER_LIST: "USER_LIST",
  RESET_USER_LIST: "RESET_USER_LIST",
  PROPERTY_OWNER: "PROPERTY_OWNER",
  RESET_PROPERTY_OWNER: "RESET_PROPERTY_OWNER",
  RESET_BRAND_OWNER: "RESET_BRAND_OWNER",
  RESET_BRAND: "RESET_BRAND",
  RESET_CATEGORY: "RESET_CATEGORY",
  RESET_PRODUCTS: "RESET_PRODUCTS",
  STORE: "STORE",
  LOADING: "LOADING",
  WAREHOUSE: "WAREHOUSE",
  PRODUCTS: "PRODUCTS",
  CATEGORY: "CATEGORY",
  BRAND: "BRAND",
  BRAND_OWNER: "BRAND_OWNER",
  RESET_RECIPES: "RESET_RECIPES",
  RECIPES: "RECIPES",
  CUSTOMER: "CUSTOMER",
  RESET_CUSTOMER: "RESET_CUSTOMER",
  WAREHOUSE_STOCK: "WAREHOUSE_STOCK",
  STORE_STOCK: "STORE_STOCK",
  WAREHOUSE_TO_STORE: "WAREHOUSE_TO_STORE",
  WAREHOUSE_TO_WAREHOUSE: "WAREHOUSE_TO_WAREHOUSE",
  STORE_TO_WAREHOUSE: "STORE_TO_WAREHOUSE",
  STORE_TO_STORE: "STORE_TO_STORE",
  GRN: "GRN",
  STORE_GRN: "STORE_GRN",
  WAREHOUSE_GRN: "WAREHOUSE_GRN",
  SKU_TIMELINE: "SKU_TIMELINE",
  RTV_STORE_STOCK: "RTV_STORE_STOCK",
  ORDER: "ORDER",
  PO: "PO",
  DASHBOARD: "DASHBOARD",
  BANNER: "BANNER",
  ONLINE_ORDERS: "ONLINE_ORDERS",
  WAREHOUSE_PO: "WAREHOUSE_PO",
  COMMON: "COMMON",
  RETURNS: "RETURNS",
  STORE_AND_WAREHOUSE_REPORTS: "STORE_AND_WAREHOUSE_REPORTS",
  CREDIT: "CREDIT",
  E_PRODUCTS: "E_PRODUCTS",
};

export default actionName;
